import { render, staticRenderFns } from "./MediaDescription.html?vue&type=template&id=7072fa84&scoped=true&"
import script from "./MediaDescription.js?vue&type=script&lang=js&"
export * from "./MediaDescription.js?vue&type=script&lang=js&"
import style0 from "./MediaDescription.scss?vue&type=style&index=0&id=7072fa84&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7072fa84",
  null
  
)

export default component.exports