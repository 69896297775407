import Vue from 'vue';
import { TYPE_MEDIA } from '@/constants';
import STORE from "../../../store/types";

export default Vue.extend({
  name: 'MediaDescription',

  props: {
    mediaType: {
      type: String,
      required: true
    }
  },

  data: () => ({
    mediTypeImage: TYPE_MEDIA.IMAGE,
    mediTypeVideo: TYPE_MEDIA.VIDEO,
    mediaUrl: '',
    headquater: {}
  }),

  components: {
    MediaDescriptionAlbums: () => import('@components/generals/MediaDescription/MediaDescriptionAlbums'),
    MediaDescriptionPanoramics: () => import('@components/generals/MediaDescription/MediaDescriptionPanoramics')
  },

  async created() {
    this.headquater = this.getHeadquaterSelectedFromUserProfile();
    await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_ALBUM, this.headquater.id);

    window.addEventListener("headquaterSelected", this.handleSelectHeadquater);
  },

  methods: {
    async handleSelectHeadquater(event) {
      this.headquater = event.detail;
      await this.$store.dispatch(STORE.ACTIONS.ALBUMS.DATA_ALBUM, this.headquater.id);
    }
  }
});
